import React from 'react';
import { Link } from "react-scroll";

const Navbar = () => {
    return (
        <>
        <div className="mainmenu">
            <div className="container">
                {/* <Link to="/" smooth={true} duration={800} className="sitelogo">
                    <img src={require('../../images/atsundown-logo.webp')} alt="atsundown-logo"/>                        
                </Link> */}

                <a href="/" className="sitelogo">
                    <img src={require('../../images/atsundown-logo.webp')} alt="At Sundown: Shots in the Dark Logo"/> 
                </a>

                <div className="socialLinks">
                    <a href="https://bit.ly/sundown-discord">
                        {/* <img src={require('../../images/Discord-Logo-White.png')} alt="atsundown-discord"/> */}
                        <i className="fab fa-discord"></i>
                    </a>
                    <a href="https://bit.ly/sundown-twitter">
                        {/* <img src={require('../../images/Twitter_Social_Icon_Circle_White.png')} alt="atsundown-twitter"/> */}
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://bit.ly/sundown-fb">
                        {/* <img src={require('../../images/f_logo_RGB-White_250.png')} alt="atsundown-facebook"/> */}
                        <i className="fab fa-facebook"></i>
                    </a>
                </div>

                <div className="header-menu d-none">
                        
                    <Link to="trailer" spy={true} smooth={true} offset={0} duration={100}>Trailer</Link>
                    {/* <a href="#trailer">Trailer</a> */}

                    <Link to="about" spy={true} smooth={true} offset={0} duration={100}>About</Link>
                    {/* <a href="#about">About</a> */}

                    <Link to="gallery" spy={true} smooth={true} offset={0} duration={100} >Gallery</Link>
                    {/* <a href="#gallery">Gallery</a> */}

                    <Link to="buy" spy={true} smooth={true} offset={0} duration={100} >Buy</Link>
                    {/* <a href="#buy">Buy</a> */}

                </div>
            </div>
        </div>

        </>
    )
}


export default Navbar;


