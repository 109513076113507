import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

const Wrapper = styled.div`
    width: 100%;
    padding: 30px;
`;

const Page = styled.div`
    width: 100%;
    padding: 30px;
`;

const SlideView = () => {
    return (
            <>
                <Wrapper>
                    <Slider 
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        infinite={true}
                        dots={true}
                    >
                        <Page>
                            <img src={require('../../images/Screenshots/atsundown_screen_1.webp')} alt="Characters shooting in a subway station"/>
                        </Page>
                        <Page>
                            <img src={require('../../images/Screenshots/atsundown_screen_3.webp')} alt="Characters shooting in a laboratory"/>
                        </Page>
                        <Page>
                            <img src={require('../../images/Screenshots/atsundown_screen_4.webp')} alt="Characters shooting in a garden"/>
                        </Page>
                    </Slider>                    
                </Wrapper>
            </>
        )
    }
    
export default SlideView;