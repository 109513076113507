import ReactFullpage from '@fullpage/react-fullpage'
import React from 'react'
import ParallaxMousemove from 'react-parallax-mousemove'
import ReactPlayer from 'react-player'
import "../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../node_modules/slick-carousel/slick/slick.css"
import SlideView from '../components/SlideView'
import Spotlight from "../components/spotlight.js"
import AboutVideo from "../videos/about.mp4"
import Trailer from "../videos/At_Sundown_Abridged_Compressed.m4v"

const Home = () => {
    
    return (
        <div className="layout">
            <ReactFullpage
                //fullpage options
				licenseKey = {'B72463A9-F33947E9-A9B77B0A-49ED7207'}
                scrollingSpeed = {1000}
                navigation
                navigationTooltips={['Trailer', 'About', 'Gallery', 'Buy Now']}
                render={({ state, fullpageApi }) => {
                return (
                    <ReactFullpage.Wrapper>
                        <div class="section" id="trailer">
                            <Spotlight>
                                <div className="container">
                                    <div className="row">
                                        <div className='top-divider'></div>
                                    </div>
                                    <div className="row">
                                        {/* Trailer Section */}
                                        <div className="col-xl-6 ">
                                            <div className="heightcenter">
                                                <div className="character-img">
                                                <ParallaxMousemove.Layer 
                                                layerStyle={{
                                                    position: 'absolute',
                                                    transform: 'translate(0%, 0%)',
                                                }}
                                                config={{
                                                    xFactor: 0.05,
                                                    yFactor: -0.05,
                                                    springSettings: {
                                                        stiffness: 120,
                                                        damping: 10
                                                    }
                                                }}>
                                                    <img src={require('../images/character/Dawn_transparent.webp')} alt="A woman dressed in red holding two revolvers"/>
                                                </ParallaxMousemove.Layer>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 ">
                                            <div className="heightcenter">
                                                <div className="screen-img mt-mob">
                                                    <h1>At Sundown: Shots in the Dark</h1>
                                                    <div className="player-wrapper">
                                                    <ReactPlayer url={Trailer} muted='true' loop='true' playing='true' controls='true' height='400' width='100%' />
                                                    </div>
                                                    <p>Hide in the shadows and kill your friends in this frantic twin-stick shooter!</p>
                                                </div>
                                                <div className="screen-bottom-img mt-20">
                                                    <div className="sb-block screen-button-steam">
                                                        <a href="http://bit.ly/as-steam" target="_blank1">
                                                            <img src={require('../images/console-button/steam-logo.png')} alt="steam-logo"/>
                                                        </a>
                                                    </div>
                                                    <div className="sb-block screen-button-ps4">
                                                        <a href="https://bit.ly/as-ps4" target="_blank2">
                                                            <img src={require('../images/console-button/ps4-logo.png')} alt="Playstation 4 Button"/>
                                                        </a>
                                                    </div>                      
                                                    <div className="sb-block screen-button-xbox">
                                                        <a href="https://bit.ly/as-xbox" target="_blank3">
                                                            <img src={require('../images/console-button/xbox-logo.png')} alt="Xbox One Button"/>
                                                        </a>
                                                    </div>                        
                                                    <div className="sb-block screen-button-switch">
                                                        <a href="https://bit.ly/as-switch" target="_blank4">
                                                            <img src={require('../images/console-button/switch-logo.png')} alt="Nintendo Switch Button"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <img style={{zIndex: 1}} src={require('../images/atsundown-esrb.jpg')} alt="ESRB Rating: Teen for Mild Blood and Violence"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Spotlight>
                        </div>
                        <div class ="section" id="about">
                            <Spotlight>
                                <div className="container">
                                    <div className="row">
                                        {/* About Section */}
                                        <div className="col-xl-6">
                                            <div className="heightcenter">
                                                <div className="screen-img">
                                                    <h2>Hide in the shadows...</h2>
                                                    <ReactPlayer className="video-container" url={AboutVideo} playing='true' loop='true' controls='true' muted="muted" height="450"/>
                                                    <h2>...and go in for the KILL!</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="heightcenter mt-60">
                                                <div className="character-img">
                                                    <ParallaxMousemove.Layer 
                                                    layerStyle={{
                                                        position: 'absolute',
                                                        transform: 'translate(0%, 15%)',
                                                    }}
                                                    config={{
                                                        xFactor: 0.05,
                                                        yFactor: -0.05,
                                                        springSettings: {
                                                            stiffness: 120,
                                                            damping: 10
                                                        }
                                                    }}>
                                                        <img src={require('../images/character/Dusk_transparent.webp')} alt="A man dressed in green holding a shotgun"/>
                                                    </ParallaxMousemove.Layer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Spotlight>
                        </div>
                        <div class = "section" id="gallery">
                            <Spotlight>
                                <div className="container">
                                    <div className="row" >

                                        {/* Gallery Section */}
                                        <div className="col-xl-6">
                                            <div className="heightcenter">
                                                <div className="character-img spacialchar">
                                                    <ParallaxMousemove.Layer 
                                                    layerStyle={{
                                                        position: 'absolute',
                                                        transform: 'translate(-5%, 5%)',
                                                    }}
                                                    config={{
                                                        xFactor: 0.05,
                                                        yFactor: -0.05,
                                                        springSettings: {
                                                            stiffness: 120,
                                                            damping: 10
                                                        }
                                                    }}>
                                                        <img src={require('../images/character/Eve_transparent.webp')} alt="A woman dressed in blue holding a sniper rifle"/>
                                                    </ParallaxMousemove.Layer>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="screen-img heightcenter">
                                                <h2>Gallery</h2>
                                                <SlideView/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Spotlight>
                        </div>
                        <div class = "section" id="buy">
                            <Spotlight>
                                <div className="container">
                                    <div className="row">
                                        {/* Buy Section */}
                                        <div className="col-xl-6">
                                            <div className="heightcenter mt-60">
                                                <div className="screen-img">
                                                    <div className="mb-60">
                                                        <h2>BUY NOW</h2>
                                                    </div>                            
                                                    <div className="buy-now-img">
                                                        <div className="row centered">
                                                            <div className="col-xl-6 col-6">
                                                                <div className="single-buy-now one">
                                                                    <a href="https://bit.ly/as-steam" target="_blank5">
                                                                        <img src={require('../images/console-button/steam-logo.png')} alt="Steam Button"/>
                                                                    </a>
                                                                </div>                        
                                                            </div>                        
                                                            <div className="col-xl-6 col-6">
                                                                <div className="single-buy-now two">
                                                                    <a href="https://bit.ly/as-ps4" target="_blank6">
                                                                        <img src={require('../images/console-button/ps4-logo.png')} alt="Playstation 4 Button"/>
                                                                    </a>
                                                                </div>                        
                                                            </div>                    
                                                            <div className="col-xl-6 col-6">
                                                                <div className="single-buy-now three">
                                                                    <a href="https://bit.ly/as-xbox" target="_blank7">
                                                                        <img src={require('../images/console-button/xbox-logo.png')} alt="Xbox One Button"/>
                                                                    </a>
                                                                </div>                        
                                                            </div>                        
                                                            <div className="col-xl-6 col-6">
                                                                <div className="single-buy-now four">
                                                                    <a href="https://bit.ly/as-switch" target="_blank8">
                                                                        <img src={require('../images/console-button/switch-logo.png')} alt="Nintendo Switch Button"/>
                                                                    </a>
                                                                </div>                        
                                                            </div>
                                                            <div className="col mb-40">
                                                                <img style={{width: "auto"}} src={require('../images/atsundown-esrb.jpg')} alt="ESRB Rating: Rated Teen for Mild Blood and Violence"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="bottom-paragraph">©2021 Mild Beast. At Sundown: Shots in the Dark is a registered trademark of Mild Beast. Versus Evil and the Versus Evil logo are trademarks of Versus Evil LLC. “PlayStation Family Mark” and “PS4 logo” are registered trademarks or trademarks of Sony Interactive Entertainment Inc. Nintendo Switch is a trademark of Nintendo. ©2018 Valve Corporation. Steam and the Steam logo are trademarks and/or registered trademarks of Valve Corporation in the U.S. and/or other countries. All other trademarks and logos are property of their respective owners. All rights reserved.</p>
                                                    <p className="bottom-paragraph-bold"><strong>Designed and Developed by CreateDevs</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="heightcenter">
                                                <div className="character-img">
                                                    <ParallaxMousemove.Layer 
                                                    layerStyle={{
                                                        position: 'absolute',
                                                        transform: 'translate(5%, 10%)',
                                                    }}
                                                    config={{
                                                        xFactor: 0.05,
                                                        yFactor: -0.05,
                                                        springSettings: {
                                                            stiffness: 120,
                                                            damping: 10
                                                        }
                                                    }}>
                                                        <img src={require('../images/character/Midnight_transparent.webp')} alt="A man dressed in yellow holding a sword"/>
                                                    </ParallaxMousemove.Layer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Spotlight>
                        </div>
                    </ReactFullpage.Wrapper>
                );
                }}
            />
        </div>
    )
}

export default Home
