import React, { useEffect, useState } from 'react';

const Spotlight = ({children}) => {
    const [mousePositionX, setMousePositionX] = useState();
    const [mousePositionY, setMousePositionY] = useState();

    const updateMousePosition = e => {
        setMousePositionX(e.pageX);
        setMousePositionY(e.pageY);
    };
    
    useEffect(() => {
        window.addEventListener("mousemove", updateMousePosition);
    
        return () => window.removeEventListener("mousemove", updateMousePosition);

    }, []);

    return (
        <>
        <div className="spotlight" style={{backgroundImage: `radial-gradient(circle at ${mousePositionX / window.innerWidth * 100}% ${mousePositionY / window.innerHeight * 100}%, transparent 160px, rgba(0, 0, 0, 0.85) 200px)`}}></div>
        {children}
        </>
    )
}

export default Spotlight
